import { useState, useEffect } from "react";
import axios from "axios";

function useUserInnovation() {
  const [listOfInnovations, setListOfInnovations] = useState([]);
  
  useEffect(() => {
    axios
      .get(`https://api.ghsdginnovations.org/api/innovations/getAll`)
      .then((response) => {
        const Innovations = response.data;
        setListOfInnovations(Innovations);
      })
      .catch((error) => {
        console.error('Error fetching innovations:', error);
      });
  }, []);

  return [listOfInnovations];
}

export default useUserInnovation;
