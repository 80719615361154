import React from 'react';
import { Container, Typography, Box } from '@mui/material';
import Layout from '../pages/home/layout';
const HelpPage = () => {
  return (
     <Layout>
    <Container sx={{ marginTop: '120px',  marginTop: '120px' }}>
      <Box>
      <Typography 
  variant="h6" 
  gutterBottom 
  style={{
    fontFamily: 'Noto Sans, sans-serif',
    fontWeight: 'bold',
    color: '#0468B1', // A nice shade of blue
    letterSpacing: '1px',
    textTransform: 'uppercase',
    marginBottom: '15px',
  }}
>
          Help and Support
        </Typography>
        <hr/>
        <Typography variant="body1" paragraph style={{ textAlign: 'justify' }}>
        Welcome to the help page tailored for investors. Here, you can find answers to frequently asked questions and guidance on how to navigate your role in the application.
</Typography>

        <Typography  gutterBottom style={{ fontWeight: 'bold' }}> 
          Frequently Asked Questions
        </Typography>
        <Typography variant="body1" paragraph style={{ textAlign: 'justify' }}>
          <strong>Q1:</strong> How do I view innovations to invest in?<br />
          <strong>A:</strong>Navigate to the "Innovations" page, where you can browse and review all listed innovations. You can filter innovations by various criteria to find those that align with your investment goals.
        </Typography>
        <Typography variant="body1" paragraph style={{ textAlign: 'justify' }}>
          <strong>Q2:</strong> How do I pledge financial support to an innovation?<br />
          <strong>A:</strong> Once you find an innovation that interests you, click on it to view more details. You'll find an option to invest, where you can specify the amount you'd like to contribute and confirm your pledge.
        </Typography>
        <Typography variant="body1" paragraph style={{ textAlign: 'justify' }}>
          <strong>Q3:</strong> How can I provide feedback on an innovation?<br />
          <strong>A:</strong> On the innovation's detail page, scroll down to the comments section. Here, you can leave your insights, suggestions, or any other feedback that might help improve the innovation.
        </Typography>
        
        <Typography gutterBottom style={{ fontWeight: 'bold' }}>
          Contact Support
        </Typography>
        <Typography variant="body1" paragraph style={{ textAlign: 'justify' }}>
        If you need further assistance or have any specific questions, please contact our support team at <b>support@ghsdginnovations.org</b>. We’re here to help you make the most of your investment experience.
        </Typography>
      </Box>
    </Container>
    <br/>
    </Layout>
  );
};

export default HelpPage;
