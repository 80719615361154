import React, { useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import Logo from "../../components/Logo";
import { Input, Form } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import "../../pages/login/style.css";
import axios from "../../helpers/axios";
import { authConstants } from "../../actions/constants";
import { Box, styled } from "@mui/system";
import { Card, Button, Stack, TextField } from '@mui/material';
import swal from 'sweetalert';

const FlexBox = styled(Box)(() => ({
  display: 'flex',
  alignItems: 'center',
}));

const JustifyBox = styled(FlexBox)(() => ({
  justifyContent: 'center',
}));

styled('img')(() => ({
  width: '100%',
}));

const JWTRegister = styled(JustifyBox)(() => ({
  background: '',
  minHeight: '100vh !important',
  '& .card': {
    maxWidth: 500,
    borderRadius: 12,
    margin: '1rem',
    minHeight: 500, 
  },
}));

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const auth = useSelector((state) => state.auth);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const userLogin = async (e) => {
    e.preventDefault();
    setIsLoading(true); // Set loading state to true when the login process starts
    try {
      const res = await axios.post(`/auth/login`, {
        email,
        password,
      });
      const { token, userId, role } = res.data;

      if (role !== "investor") {
        await swal("Login Failed", "Only investors can log in", "error");
        setIsLoading(false); // Reset loading state on failure
        return;
      }

      localStorage.setItem("token", token);
      localStorage.setItem("userId", userId);
      dispatch({
        type: authConstants.LOGIN_SUCCESS,
        payload: {
          token,
          userId,
        },
      });
      navigate("/");
    } catch (error) {
      console.error("Login failed:", error);
      const errorMessage = error.response?.data?.error || 'There was an error during login';
      await swal('Login Failed', errorMessage, 'error');
    }
    setIsLoading(false); // Reset loading state after the login process completes
  };

  return (
    <JWTRegister>
      <Card className="card text-center">
        <div className="d-flex flex-column col">
         
          <Box p={4} height="900">
          <div className="d-flex align-items-center justify-content-center mb-4">
    <Box component="img" src="https://digitalx.undp.org/images/630478c456d6a9a3e003e9f2_logo-blue.svg" sx={{ width: 74, height: 130 }} />
  </div>
            <h4 style={{ marginBottom: '20px' }}> Investor Sign In</h4>
            <Form onSubmit={userLogin}>
              <Stack spacing={4}>
                <TextField
                style={{ marginBottom: '20px' }}
                  className='form-control'
                  placeholder='Email'
                  value={email}
                  type="email"
                  onChange={(e) => setEmail(e.target.value)}
                />
                <TextField
                style={{ marginBottom: '20px' }}
                  className='form-control'
                  placeholder='Password'
                  value={password}
                  type="password"
                  onChange={(e) => setPassword(e.target.value)}
                />
              </Stack>
             
              <div className="form-group" style={{ marginTop: '20px' }}>
                <Button
                  type="submit"
                  sx={{ textTransform: 'capitalize' }}
                  disabled={isLoading}
                >
                  {isLoading ? 'Please wait...' : 'Login'}
                </Button> |
                <Button
                  sx={{ textTransform: 'capitalize' }}
                  onClick={() => navigate("/register/")}
                >
               Sign Up
                </Button>
              </div>
              <div className="my-1 text-left">
                <Button
                  sx={{ textTransform: 'capitalize', color: "black" }}
                  onClick={() => navigate("/")}
                >
                  Forgot password? Reset
                </Button>
              </div>
            </Form>
          </Box>
        </div>
      </Card>
    </JWTRegister>
  );
};

export default Login;
