import React, { useState, useEffect } from 'react';
import { Box, styled } from '@mui/system';
import { Card, Grid, Button, Stack, TextField, Typography } from '@mui/material';
import { Form } from 'reactstrap';
import { useNavigate } from "react-router";
import swal from 'sweetalert';
import axios from "../../helpers/axios";
import Logo from "../../components/Logo";
import { Span } from "../../components/Typography";

const FlexBox = styled(Box)(() => ({
    display: 'flex',
    alignItems: 'center',
}));

const JustifyBox = styled(FlexBox)(() => ({
    justifyContent: 'center',
}));

const ContentBox = styled(JustifyBox)(() => ({
    height: '100%',
    padding: '32px',
    background: 'rgba(0, 0, 0, 0.01)',
}));

const JWTRegister = styled(JustifyBox)(() => ({
    background: '',
    minHeight: '100vh !important',
    '& .card': {
        maxWidth: 900,
        borderRadius: 12,
        margin: '1rem',
        minHeight: 650, 
    },
}));

const formDataInitial = {
    fullName: '',
    email: '',
    password: '',
    role: 'investor',
};

const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
};

const validatePassword = (password) => {
    const hasUppercase = /[A-Z]/.test(password);
    const hasNumber = /[0-9]/.test(password);

    const isValid = hasUppercase && hasNumber ;
    let strength = 'Weak';
    if (isValid) {
        strength = 'Strong';
    }
    return { isValid, strength };
};

const Register = () => {
    const [formData, setFormData] = useState(formDataInitial);
    const [isLoading, setIsLoading] = useState(false);
    const [isEmailValid, setIsEmailValid] = useState(false);
    const [passwordStatus, setPasswordStatus] = useState({ isValid: false, strength: 'Weak' });
    const [isPasswordFocused, setIsPasswordFocused] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        setIsEmailValid(validateEmail(formData.email));
        setPasswordStatus(validatePassword(formData.password));
    }, [formData.email, formData.password]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prev => ({ ...prev, [name]: value }));
    };

    const handlePasswordFocus = () => {
        setIsPasswordFocused(true);
    };

    const userSignup = async (e) => {
        e.preventDefault();
        if (!isEmailValid || !passwordStatus.isValid) {
            return;
        }
        setIsLoading(true);
        try {
            await axios.post(`/auth/register`, formData);
            setIsLoading(false);
            await swal('Success', 'Account sign up successful', 'success');
            navigate('/login');
        } catch (err) {
            setIsLoading(false);
            const message = err.response?.status === 422 ? err.response.data.error : 'An error occurred. Please try again!';
            await swal('Sign up Error', message, 'error');
        }
    };

    return (
        <JWTRegister>
            <Card className="card text-center">
                <div className="d-flex flex-column col">
                    
                    <Box p={4} height="100%">
                    <div className="d-flex align-items-center justify-content-center mb-4">
    <Box component="img" src="https://digitalx.undp.org/images/630478c456d6a9a3e003e9f2_logo-blue.svg" sx={{ width: 74, height: 130 }} />
  </div>
                        <h4  style={{ marginBottom: '20px' }}>Sign Up</h4>
                        <Form onSubmit={userSignup}>
                            <Stack spacing={4}>
                                <TextField
                                
                                    variant="outlined"
                                    label="Full Name"
                                    name="fullName"
                                    value={formData.fullName}
                                    onChange={handleChange}
                                    fullWidth
                                    required
                                />
                                <TextField
                                    variant="outlined"
                                    label="Email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    fullWidth
                                    required
                                    error={!isEmailValid && formData.email.length > 0}
                                    helperText={!isEmailValid && formData.email.length > 0 ? 'Invalid email address' : ''}
                                />
                                <TextField
                                    variant="outlined"
                                    label="Password"
                                    name="password"
                                    type="password"
                                    value={formData.password}
                                    onChange={handleChange}
                                    onFocus={handlePasswordFocus}
                                    fullWidth
                                    required
                                    error={!passwordStatus.isValid && formData.password.length > 0}
                                    helperText={!passwordStatus.isValid && formData.password.length > 0 ? 'Password must contain an uppercase letter, and a number' : ''}
                                />
                                {isPasswordFocused && (
                                    <Typography color={passwordStatus.isValid ? 'green' : 'red'}>
                                        Password strength: {passwordStatus.strength}
                                    </Typography>
                                )}
                                <TextField
                                    variant="outlined"
                                    label="Role"
                                    name="role"
                                    value={formData.role}
                                    onChange={handleChange}
                                    fullWidth
                                    disabled
                                />
                            </Stack>
                            <br />
                            <div className="form-group">
                                <Button
                                    type="submit"
                                    disabled={isLoading}
                                    sx={{ textTransform: 'capitalize' }}
                                >
                                    {isLoading ? 'Please wait...' : 'Sign up'}
                                </Button>
                                <Span sx={{ mr: 1, ml: '20px' }}>or</Span>
                                <Button
                                    sx={{ textTransform: 'capitalize' }}
                                    onClick={() => navigate("/login/")}
                                >
                                    Sign in
                                </Button>
                            </div>
                        </Form>
                    </Box>
                </div>
            </Card>
        </JWTRegister>
    );
};

export default Register;
